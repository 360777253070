import AVATAR1 from '../../images/visitantes/avatar-stories-01-default.jpg';
import AVATAR2 from '../../images/visitantes/avatar-stories-02-default.jpg';
import MEDIA1 from '../../images/visitantes/stories-01-default.jpg';
import MEDIA2 from '../../images/visitantes/stories-02-default.jpg';
import MEDIA3 from '../../images/visitantes/stories-03-default.jpg';

export default function VisitantesDefault() {
    const date = (new Date()).getTime();
    return [
        {
            "name": "Astronauta",
            "horario": date - 120000,
            "avatar": AVATAR1,
            "media": MEDIA1,
            "legenda": "Fui na órbita e volto 🚀✨"
        },
        {
            "name": "Nadador míope",
            "horario": date - 60000,
            "avatar": AVATAR2,
            "media": MEDIA2,
            "legenda": "🏊👓"
        },
        { "media": MEDIA3, }
    ]
}