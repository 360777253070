import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Rotas from './routes';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Rotas />
  </React.StrictMode>
);

export const AVATAR_FILES = () => {
  const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  let arrReturn = []
  for (let i of arr) {
    let img = require(`./images/CAvatars/${i < 10 ? '0' + i : i}.webp`);
    arrReturn.push(img);
  }
  return arrReturn
}

reportWebVitals();