import axios from '../baseService';
import Time from '../../components/Time/Time';

const API_URL = process.env.REACT_APP_API_URL;

function formatFolderName(name, timestamp) {
    return `${name.toLowerCase().replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '')}-${Time(timestamp, 'data')}-${Time(timestamp, 'hora')}`;
}

export async function iniciarEvento(data, token) {
    const folderName = formatFolderName(data.evento, data.inicio);
    const apiUrl = `${API_URL}/stories/iniciar/${folderName}`;
    const response = await axios.post(apiUrl, { data, token });
    return response.data;
}

export async function consultaPower(endpoint = '') {
    const apiUrl = `${API_URL}/stories/consulta/${endpoint}`;
    const response = await axios.get(apiUrl);
    return response.data;
}

export async function trocaFrame(evento, frame, endpoint, token) {
    const apiUrl = `${API_URL}/stories/frame/${endpoint}`;
    const response = await axios.post(apiUrl, { evento, frame, endpoint, token });
    return response.data;
}

export async function sendUserUpdate(usuario, senha, endpoint, token) {
    const apiUrl = `${API_URL}/stories/user/${endpoint}`;
    const response = await axios.post(apiUrl, { usuario, senha, endpoint, token });
    return response.data;
}

export async function alteraDQ(tempo, quantidade, qr, token, endpoint) {
    const apiUrl = `${API_URL}/stories/manage/numeros/${endpoint}`;
    const response = await axios.post(apiUrl, { tempo, quantidade, qr, token, endpoint });
    return response.data;
}

export async function aprovacaoSend(aprovacao, endpoint, token) {
    const apiUrl = `${API_URL}/stories/manage/aprovacao/${endpoint}`;
    const response = await axios.post(apiUrl, { aprovacao, endpoint, token });
    return response.data;
}

export async function encerrarEvento(token, evento, folderName) {
    const apiUrl = `${API_URL}/stories/encerrar/${folderName}`;
    const response = await axios.post(apiUrl, { token, evento, folderName });
    return response.data;
}

export async function aprovar(id, token, endpoint) {
    const apiUrl = `${API_URL}/stories/manage/aprovar/${endpoint}`;
    const response = await axios.put(apiUrl, { id, token, endpoint });
    return response.data;
}

export async function excluir(id, token, endpoint) {
    const apiUrl = `${API_URL}/stories/manage/excluir/${endpoint}`;
    const response = await axios.post(apiUrl, { id, token, endpoint });
    return response.data;
}

export async function ativaPubli(id, token) {
    const apiUrl = `${API_URL}/stories/publi/manage/ativar`;
    const response = await axios.put(apiUrl, { id, token });
    return response.data;
}

export async function pausaPubli(id, token) {
    const apiUrl = `${API_URL}/stories/publi/manage/pausar`;
    const response = await axios.put(apiUrl, { id, token });
    return response.data;
}

export async function excluiPubli(id, token) {
    const apiUrl = `${API_URL}/stories/publi/manage/deletar`;
    const response = await axios.post(apiUrl, { id, token });
    return response.data;
}

export async function limpaTudo(token, folderName) {
    const apiUrl = `${API_URL}/stories/manage/clean/${folderName}`;
    const response = await axios.post(apiUrl, { token, folderName });
    return response.data;
}

export async function downloadPhotos(frame, folderName, token) {
    const apiUrl = `${API_URL}/stories/download/${folderName}`;
    const response = await axios.post(apiUrl, { frame, folderName, token }/* , { responseType: 'blob' } */ );
    return response.data;
}