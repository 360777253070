import React, { useState, useEffect } from "react";

/**
 * props 
 * - aplicativo
 */
function Assinatura(props) {

    const [aplicativo, setAplicativo] = useState('');

    useEffect(() => {
        setAplicativo(props.aplicativo);
    }, [props.aplicativo])

    return (
        <div className="assinatura">
            <div className="nome"><span>{aplicativo}</span>evento</div>
        </div>
    )
}

export default Assinatura;