import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import './App.css';
import Stories from './public/Stories/Stories';
import OpenStories from './components/OpenStories/OpenStories';
import useWebSocket from 'react-use-websocket';

function App() {
  const url = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [evento, setEvento] = useState('');
  const [source, setSource] = useState('inicial');
  const [showQr, setShowQr] = useState(true);
  const [aprova, setAprova] = useState({});
  const [exclui, setExclui] = useState({});
  const [endpoint, setEndpoint] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [tempoQuantidade, setTempoQuantidade] = useState({
    tempo: 10,
    quantidade: 10
  });

  const { lastJsonMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}?systemId=${url.dynamicRoute}`, {
    onOpen: () => console.log(`Connected to App WS`),
    onMessage: () => {
      if (lastJsonMessage) {
        console.log(lastJsonMessage)
        setIsLoading(false);
        if (lastJsonMessage.notice && lastJsonMessage.notice[0] === 'source' && lastJsonMessage.notice[1] !== source) setSource(lastJsonMessage.notice[1]);
        else {
          if (lastJsonMessage.source && lastJsonMessage.source.length > 0) setSource(lastJsonMessage.source);
        }
        if (lastJsonMessage.notice && lastJsonMessage.notice[0] === 'aprova' && lastJsonMessage.notice[1] !== aprova) setAprova(lastJsonMessage.notice[1]);
        if (lastJsonMessage.notice && lastJsonMessage.notice[0] === 'exclui' && lastJsonMessage.notice[1] !== exclui) setExclui(lastJsonMessage.notice[1]);

        if (lastJsonMessage.qr) setShowQr(lastJsonMessage.qr);
        if (lastJsonMessage.tempo && lastJsonMessage.tempo !== tempoQuantidade.tempo) {
          tempoQuantidade.tempo = lastJsonMessage.tempo;
          setTempoQuantidade({ ...tempoQuantidade })
        }
        if (lastJsonMessage.quantidade && lastJsonMessage.quantidade !== tempoQuantidade.quantidade) {
          tempoQuantidade.quantidade = lastJsonMessage.quantidade;
          setTempoQuantidade({ ...tempoQuantidade })
        }
      }
    },
    onError: (event) => {
      console.error(event);
    },
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 1000
  });

  useEffect(() => {
    setEndpoint(url.dynamicRoute)
  }, [])

  useEffect(() => {
    if (isLoading) return;
    if (!evento && lastJsonMessage && lastJsonMessage.evento) setEvento(lastJsonMessage.evento);
  }, [lastJsonMessage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (evento && evento.length > 0) return;
      else {
        if (!shouldRedirect) setShouldRedirect(true);
      }
    }, 10000);

    return () => shouldRedirect ? null : clearTimeout(timeoutId);
  }, [evento, endpoint, shouldRedirect]);

  if (shouldRedirect) {
    return <Navigate to={'/'} />;
  }

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    evento && evento.length > 0 ? (
      <div className="App">
        {
          source && source === 'inicial'
            ? (<OpenStories evento={evento} endpoint={endpoint} />)
            : source === 'stories'
              ? (<Stories endpoint={endpoint} evento={evento} tempo={tempoQuantidade.tempo} showQr={showQr} aprova={aprova} exclui={exclui} quantidade={tempoQuantidade.quantidade} />)
              : <React.Fragment></React.Fragment>
        }
      </div>
    )
      : <></>
  );
}

export default App;
