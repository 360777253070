import axios from 'axios';
import { createCanvas, loadImage } from 'canvas';

export async function ThumbFoto(url, ladoQuadrado) {
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const blobUrl = URL.createObjectURL(blob);

        const imagem = await loadImage(blobUrl);
        const imgSize = Math.min(imagem.width, imagem.height);

        const left = (imagem.width - imgSize) / 2;
        const top = (imagem.height - imgSize) / 2;

        const canvas = createCanvas(ladoQuadrado, ladoQuadrado);
        const context = canvas.getContext('2d');

        context.drawImage(imagem, left, top, imgSize, imgSize, 0, 0, ladoQuadrado, ladoQuadrado);

        const imagemRedimensionada = canvas.toDataURL('image/png');

        console.log('Imagem redimensionada com sucesso.');
        return imagemRedimensionada;
    } catch (error) {
        console.error(`Erro ao carregar imagem da URL: ${error.message}`);
        return null;
    }
};

export async function FotoComFrame(foto, frame) {
    const canvas = createCanvas(675, 1200);
    const context = canvas.getContext('2d');

    const loadPhoto = async () => {
        return new Promise((resolve, reject) => {
            const photo = new Image();
            photo.crossOrigin = '*';
            photo.onload = () => resolve(photo);
            photo.onerror = reject;
            photo.src = foto;
        });
    };

    const loadFrame = async () => {
        return new Promise((resolve, reject) => {
            const moldura = new Image();
            moldura.crossOrigin = '*';
            moldura.onload = () => resolve(moldura);
            moldura.onerror = reject;
            moldura.src = frame;
        });
    };

    try {
        const photo = await loadPhoto();
        const frameImage = await loadFrame();

        var scale = Math.max(canvas.width / photo.width, canvas.height / photo.height);
        var x = (canvas.width / 2) - (photo.width / 2) * scale;
        var y = (canvas.height / 2) - (photo.height / 2) * scale;
        
        context.drawImage(photo, x, y, photo.width * scale, photo.height * scale);
        context.drawImage(frameImage, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL('image/png');
    } catch (error) {
        console.error('Erro ao carregar imagem ou moldura:', error.message);
        throw error;
    }
}
