import React, { useState, useEffect, useRef } from "react";
import QRCodeGen from "../../components/QRcodeGen/QRcodeGen";
import Stories from "./Stories";
import useWebSocket from "react-use-websocket";
import { Link } from "react-router-dom";

function Home() {

    const storiesRef = useRef(null);
    const endpoint = 'visitantes';
    const url = process.env.REACT_APP_FRONT_URL + '/stories/envio/' + endpoint;
    const size = 170;

    const [isMobile, setIsMobile] = useState(false);
    const [aprova, setAprova] = useState({});
    
    useEffect(() => {
        if (storiesRef.current && window.location.hash === '#stories') storiesRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [storiesRef.current]);

    useEffect(() => {
        const isMobileFlag = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileFlag);
    }, [])

    const { lastJsonMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}?systemId=${endpoint}`, {
        onOpen: () => {
            console.log(`Connected to App WS`);
        },
        onMessage: () => {
            if (lastJsonMessage) {
                if (lastJsonMessage && lastJsonMessage.media && aprova !== lastJsonMessage) setAprova(lastJsonMessage);
            }
        },
        onError: (event) => {
            console.error(event);
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 1000
    });

    return (
        <div className="OpenStories Home fadein">
            <div className="area" >
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            {
                lastJsonMessage
                    ? (
                        <div className="context">
                            <div className="textHome">
                                <h1><span>stories</span>evento</h1>
                                <p><span style={{
                                    fontWeight: 'bold', backgroundColor: '#57d8ff', color: '#000',
                                    padding: '0 5px'
                                }}>Seu evento em fotos</span><br />Momentos especiais compartilhados em tempo real.</p>
                                <span>Participe como visitante:<br />Escaneie o QR code com a câmera do seu celular e envie sua foto.</span>
                                <div className="qrHome">{QRCodeGen({ url, size })}</div>
                                {
                                    isMobile
                                        ? <Link to={url} style={{ color: '#57d8ff', textDecoration: 'underline' }} target="_blank">Ou acesse através deste link</Link>
                                        : <></>
                                }
                                <button className="storiesSource" type="button" onClick={() => window.location.href = 'mailto:pedrofrn@gmail.com'}>Entre em contato</button>
                            </div>
                            <div id="stories" ref={storiesRef} className="areaScreen">
                                <div className="testScreen">
                                    <Stories endpoint={endpoint} evento={'visitantes'} tempo={5} showQr={false} aprova={aprova} exclui={false} quantidade={3} />
                                </div>
                            </div>
                        </div>
                    )
                    : (<div className="loadHome"><span className="loader"></span></div>)
            }
        </div>
    )
}

export default Home;