import axios from './baseService';

const API_URL = process.env.REACT_APP_API_URL;

export async function login(login, pwd, endpoint = '') {
    const authUrl = `${API_URL}/stories/login/${endpoint}`;
    const response = await axios.post(authUrl, { login, pwd, endpoint });
    return response.data;
}

export async function getToken(token) {
    const adminUrl = `${API_URL}/stories/admin`;
    const response = await axios.post(adminUrl, { token });
    return response.data;
}