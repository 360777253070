import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeGen({ url, size }) {
    return (
        <QRCode value={url} size={size} />
    );
};

export default QRCodeGen;
