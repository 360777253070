import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { pegaAPI } from "../../services/stories/postContentService";
import { getToken } from "../../services/authService";
import Resizer from "react-image-file-resizer";
import { consultaPower, alteraDQ } from "../../services/stories/adminService";
import ItensAdmin from "../../components/ItensAdmin/ItensAdmin";
import CardPainel from "../../components/CardPainel/CardPainel";
import Pagination from "../../components/Pagination/Pagination";
import PubliForm from "../../components/PubliForm/PubliForm";
import Assinatura from "../../components/Assinatura/Assinatura";
import useWebSocket from 'react-use-websocket';

function AdminSingle() {

    const url = useParams();
    const eventoRef = useRef();
    const instaRef = useRef();
    const tempoRef = useRef();
    const quantidadeRef = useRef();
    let navigate = useNavigate();

    const [endpoint, setEndpoint] = useState('');
    const [iniControl, setIniControl] = useState({
        evento: '',
        frame: '',
        inicio: 0,
        tempo: 10,
        quantidade: 10,
        qr: true
    })
    const [flagIni, setFlagIni] = useState(false);
    const [iniciado, setIniciado] = useState(false);
    const [countEvento, setCountEvento] = useState({
        hora: 0,
        minuto: 0,
        segundo: 0
    });
    const [api, setApi] = useState({});
    const [loader, setLoader] = useState('none');
    const [disabled, setDisabled] = useState(false);
    const [svgSeta, setSvgSeta] = useState();
    const [publiApi, setPubliApi] = useState({});
    const [ativoTela, setAtivoTela] = useState('');
    const [showQrCheck, setShowQrCheck] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const { lastJsonMessage, sendMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}?systemId=${url.dynamicRoute}`, {
        onOpen: () => console.log(`Connected to App WS`),
        onMessage: () => {
            if (lastJsonMessage) console.log(lastJsonMessage);
        },
        onError: (event) => { console.error(event); },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 1000
    });

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * iniControl.quantidade;
        const lastPageIndex = firstPageIndex + iniControl.quantidade;
        return Array.from(api).reverse().slice(firstPageIndex, lastPageIndex);
    }, [currentPage, api])

    useEffect(() => {
        setEndpoint(url.dynamicRoute)
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            getToken(token)
                .then(res => res ? console.log('Login efetuado com sucesso.') : console.log(res.status))
                .catch(err => {
                    console.error(err);
                    window.localStorage.setItem('token', '');
                    return navigate('/stories/login/' + url.dynamicRoute);
                })
        }
        else return navigate('/stories/login/' + url.dynamicRoute);
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            consultaPower(url.dynamicRoute)
                .then(res => {
                    if (res) {
                        setIniControl((prevState) => ({ ...prevState, ...res }));
                        setIniciado(true);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [])

    useEffect(() => {
        if (iniControl.source && iniControl.source !== ativoTela) setAtivoTela(iniControl.source)
    }, [iniControl.source])

    function pegaApiFunc(src) {
        if (src === 'stories') {
            setApi({})
            pegaAPI('stories', true, endpoint)
                .then(res => {
                    setApi(res)
                })
                .catch(err => console.error(err))
        }
        if (src === 'publi') {
            pegaAPI('publi', true)
                .then(res => {
                    setPubliApi(res)
                })
                .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0 && endpoint && endpoint.length > 0) {
            pegaApiFunc('stories')
            pegaApiFunc('publi')
        }
    }, [endpoint])

    useEffect(() => {
        if (lastJsonMessage) {
            if (lastJsonMessage.tempo && lastJsonMessage.tempo !== iniControl.tempo) {
                iniControl.tempo = lastJsonMessage.tempo;
                setIniControl({ ...iniControl });
            }
            if (lastJsonMessage.quantidade && lastJsonMessage.quantidade !== iniControl.quantidade) {
                iniControl.quantidade = lastJsonMessage.quantidade;
                setIniControl({ ...iniControl });
            }

            if (api && lastJsonMessage.qtStories && lastJsonMessage.qtStories[0] > api.length) pegaApiFunc('stories');
            if (publiApi && lastJsonMessage.qtPublis && lastJsonMessage.qtPublis[0] !== publiApi.length) pegaApiFunc('publi');
        }
    }, [lastJsonMessage])

    useEffect(() => {
        if (iniciado && iniControl.inicio > 0) {
            const duracao = Date.now() - iniControl.inicio;
            let segundo = parseInt((Math.trunc((duracao / 1000).toFixed(1)))) % 60;
            let minuto = parseInt((Math.trunc((duracao / (1000 * 60)).toFixed(1)))) % 60;
            let hora = (duracao / (1000 * 60 * 60)).toFixed(1);
            countEvento.segundo = segundo;
            countEvento.minuto = minuto;
            countEvento.hora = Math.trunc(hora);
            setCountEvento({ ...countEvento });
        }
    }, [iniciado])

    useEffect(() => {
        if (iniControl.inicio > 0) {
            const interval = setInterval(() => {
                countEvento.segundo = parseInt(countEvento.segundo) + 1;
                if (countEvento.segundo === 60) {
                    countEvento.minuto = parseInt(countEvento.minuto) + 1;
                    countEvento.segundo = 0;
                }
                if (countEvento.minuto === 60) {
                    countEvento.hora = parseInt(countEvento.hora) + 1;
                    countEvento.minuto = 0;
                }
                setCountEvento({ ...countEvento });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [iniControl.inicio, countEvento.segundo])

    useEffect(() => {
        if (ativoTela) sendMessage(ativoTela)
    }, [ativoTela])

    function storiesSource() {
        setAtivoTela('stories');
        setDisabled(true);
        if (iniControl.source && iniControl.source === 'stories') return;
        else {
            iniControl.source = 'stories';
            setIniControl({ ...iniControl });
        }
    }

    function onQrChange(event) {
        if (showQrCheck) setShowQrCheck(false);
        else setShowQrCheck(true);
    }

    async function onImage(event) {
        if (event.target.files && event.target.files[0]) {
            await resizeFile(event.target.files[0]);
        }
    }

    function resizeFile(file) {
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                675,
                1200,
                "PNG",
                90,
                0,
                (uri) => {
                    iniControl.frame = uri;
                    setIniControl({ ...iniControl });
                    resolve(uri);
                },
                "base64"
            );
        });
    }

    function returnSvg() {
        if (instaRef.current.classList.contains('displayNone')) {
            instaRef.current.classList.remove('displayNone');
            return setSvgSeta('M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z');
        }
        else {
            instaRef.current.classList.add('displayNone');
            return setSvgSeta('M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z');
        }
    }

    function telaInicial() {
        if (ativoTela === 'inicial') return;
        else {
            setAtivoTela('inicial');
            setDisabled(false);
            if (iniControl.source && iniControl.source === 'inicial') return;
            else {
                iniControl.source = 'inicial';
                setIniControl({ ...iniControl });
            }
        }
    }

    function alterarDuracaoQuantidade(event) {
        const token = localStorage.getItem('token');
        let tempo = parseInt(tempoRef.current.value);
        let quantidade = parseInt(quantidadeRef.current.value);
        if (iniControl.tempo === tempo && iniControl.quantidade === quantidade && iniControl.qr === showQrCheck) return;
        iniControl.tempo = tempo;
        iniControl.quantidade = quantidade;
        iniControl.qr = showQrCheck;
        alteraDQ(tempo, quantidade, iniControl.qr, token, endpoint)
            .then(res => {
                if (res) {
                    event.target.style.background = '#76f150';
                    event.target.innerHTML = "<svg fill='none' stroke='currentColor' stroke-width='1.5' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'><path stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5'></path></svg>";
                    setTimeout(() => {
                        event.target.style.background = '#9f77d1';
                        event.target.innerHTML = 'OK'
                    }, 2000);
                }
            })
            .catch(err => console.error(err))
        return setIniControl({ ...iniControl });
    }

    function aprovacaoData(data) {
        setIniControl({
            ...iniControl,
            aprova: data
        })
    }

    return (
        <main className="painel">
            <div className="container">
                <div className="headerPainel">
                    <h1>
                        Painel de Controle
                        {
                            iniciado
                                ? (<div className="countEvento">
                                    <span>
                                        {countEvento.hora < 10 ? `0${countEvento.hora}` : countEvento.hora}:
                                        {countEvento.minuto < 10 ? `0${countEvento.minuto}` : countEvento.minuto}:
                                        {countEvento.segundo < 10 ? `0${countEvento.segundo}` : countEvento.segundo}
                                    </span>
                                </div>)
                                : <React.Fragment></React.Fragment>
                        }
                    </h1>
                    <div className="telaInicial">
                        <button onClick={telaInicial} className={ativoTela === 'inicial' ? 'telaInicialAtiva' : ''}>{ativoTela === 'inicial' ? 'Tela inicial ativada' : 'Ativar tela inicial'}</button>
                    </div>
                </div>
                <div className="tarjaStories">
                    <h2 className="titleStoriesAdmin">
                        Stories
                        <span>{iniControl.evento && iniControl.evento.length > 0 ? iniControl.evento : ''}</span>
                        {iniControl.evento && iniControl.evento.length > 0 ? <ItensAdmin evento={iniControl} api={api} handleAprovacao={aprovacaoData} endpoint={endpoint} /> : ''}
                    </h2>
                    {
                        iniciado
                            ? (<div style={{ display: 'flex' }}>
                                <div className="duracaoQuantidade">
                                    <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <input ref={tempoRef} type="number" defaultValue={iniControl.tempo} />
                                    <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                                    </svg>
                                    <input ref={quantidadeRef} type="number" defaultValue={iniControl.quantidade} />
                                    <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                                    </svg>
                                    <div>
                                        <input type="checkbox" onChange={onQrChange} checked={showQrCheck} />
                                        <span className="checkmark"></span>
                                    </div>
                                    <button onClick={alterarDuracaoQuantidade} disabled={ativoTela === 'stories' ? true : disabled}>OK</button>
                                </div>
                            </div>)
                            : <React.Fragment></React.Fragment>
                    }
                    <div className="btnsStories">
                        {
                            ativoTela && ativoTela === 'stories'
                                ? (<button className="storiesAtivo" type="button">Stories Ativado</button>)
                                : (<button className="storiesSource" type="button" onClick={storiesSource}>Ativar Stories</button>)
                        }
                    </div>
                </div>
                {/* <PubliForm publiApi={publiApi && publiApi.length > 0 ? publiApi : null} /> */}
                <div className="containerCards">
                    {
                        api && api.length > 0 && currentTableData && currentTableData.length > 0
                            ? currentTableData.map(item => (
                                <CardPainel key={`card-${item.horario}`} endpoint={endpoint} id={item.id} media={item.media} name={item.name} ident={item.ident} avatar={item.avatar} legenda={item.legenda} horario={item.horario} pendente={item.pendente} />
                            ))
                            : <React.Fragment></React.Fragment>
                    }
                </div>
                {
                    api && api.length > 0
                        ? (
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={api.length}
                                pageSize={iniControl.quantidade}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        )
                        : <React.Fragment></React.Fragment>
                }

                <div className="limitePosts">Serão apresentados os {iniControl.quantidade} envios mais recentes. Cada post tem {iniControl.tempo} segundos de duração.</div>
            </div>
            <Assinatura aplicativo='stories' />
        </main >
    )
}

export default AdminSingle;