import React, { useEffect, useState } from "react";
import { AVATAR_FILES } from "../..";

/**
 * props 
 * - callbackParent
 */
function AvatarSort(props) {

    const [files, setFiles] = useState();
    const [arr, setArr] = useState([]);
    const [chosen, setChosen] = useState();
    const [selectedImg, setSelectedImg] = useState('avatarSelected');

    useEffect(() => {
        if (files && files.length > 0) return;
        setFiles(AVATAR_FILES());
    }, [])

    useEffect(() => {
        const arrStart = sortAvatars();
        setArr(arrStart);
        setChosen(arrStart[0]);
    }, [])

    function sortAvatars() {
        let ranArr = new Array(15).fill().map((a, i) => a = i).sort(() => Math.random() - 0.5)
        return ranArr.slice(0, 6);
    }

    function selectAvatar(event) {
        setChosen(parseInt(event.target.id));
        props.callbackParent(parseInt(event.target.id));
    }
    
    return (
        <div className="AvatarSort">
            {
                files && files.length > 0
                    ? arr.map((curr, idx) => (
                        <div className="avatarPicker" key={curr + 'avatar'}>
                            <img onClick={selectAvatar} onLoad={selectAvatar} id={curr} className={arr[idx] === chosen ? selectedImg : 'avt'} src={files[parseInt(curr)]} />
                        </div>
                    ))
                    : <React.Fragment></React.Fragment>
            }
        </div>
    )
}

export default AvatarSort;