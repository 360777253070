import { graficoLinha } from './Graficos.js';
import { ThumbFoto, FotoComFrame } from './FotoToBase64.js';
import headerImg from './images/storiesevento-barra-superior-relatorio.png';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
};

const printer = pdfMake;

export default async function Relatory(evento, frame, api) {

    const pageSize = { width: 595.276, height: 841.890 };

    const imagemAleatoriaComFrame = await FotoComFrame(api[Math.floor(Math.random() * (api.length - 1))].media, frame);

    let ladoQuadrado = 44;

    const tabelaBody = await Promise.all(api.map(async obj => {
        const foto = await ThumbFoto(obj.media, ladoQuadrado);
        const date = new Date(obj.horario);

        return [
            `${date.getHours()}h${date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes()}`,
            !foto ? '' : { image: foto, width: ladoQuadrado },
            obj.name,
            obj.legenda,
            obj.download === 's' ? 'Sim' : 'Não',
            obj.shared === 's' ? 'Sim' : 'Não',
        ];
    }));

    const imgHeaderProcess = async (caminhoDaImagem) => {
        try {
            const resposta = await fetch(caminhoDaImagem);
            const blob = await resposta.blob();

            return new Promise((resolve) => {
                const leitor = new FileReader();
                leitor.onloadend = () => resolve(leitor.result);
                leitor.readAsDataURL(blob);
            });
        } catch (erro) {
            console.error('Erro ao carregar a imagem:', erro.message);
            throw erro;
        }
    };

    const headerDataUrlImg = await imgHeaderProcess(headerImg.indexOf('data:image/') === -1 ? process.env.REACT_APP_FRONT_URL + headerImg : headerImg);

    const times = api.map(obj => {
        const major = new Date(obj.horario);
        return {
            dia: major.getDate().toString().length === 1 ? '0' + major.getDate() : major.getDate(),
            mes: major.getMonth().toString().length === 1 ? '0' + (major.getMonth() + 1) : major.getMonth() + 1,
            ano: major.getFullYear(),
            hora: major.getHours(),
            min: major.getMinutes(),
            download: obj.download === 's' ? 1 : 0,
            share: obj.share === 's' ? 1 : 0,
        }
    })
    const numeroEnvios = api.length;
    let horaEnvio = [];
    let totalFotos = 1;
    let totalDownload = 1;
    let totalShare = 1;

    for (let i = 0; i < times.length; i++) {
        if (i < times.length - 1 && times[i].hora === times[i + 1].hora) {
            totalFotos++
            if (times[i].download > 0) totalDownload++
            if (times[i].share > 0) totalShare++
        }
        else {
            horaEnvio.push({ hora: times[i].hora, totalFotos: totalFotos, totalDownload: totalDownload, totalShare: totalShare })
            totalFotos = 1;
            totalDownload = 1;
            totalShare = 1;
        }
    }
    const numeroDownloads = horaEnvio.reduce((soma, objeto) => soma + (objeto.totalDownload || 0), 0);
    const numeroShare = horaEnvio.reduce((soma, objeto) => soma + (objeto.totalShare || 0), 0);

    const docDefinition = {
        header: {
            table: {
                widths: [400, 'auto'],
                body: [
                    [
                        {
                            text: [
                                { text: 'Relatório de Uso', bold: true },
                                ' - ' + evento,
                            ],
                            fontSize: 8,
                            color: '#8676ac',
                            margin: [40, 10, 0, 0]
                        },
                        {
                            absolutePosition: { x: 0, y: 0 },
                            image: headerDataUrlImg,
                            fit: [pageSize.width, pageSize.height],
                        },
                    ],
                ],
            },
            layout: 'noBorders',
        },
        footer: {
            columns: [
                { text: 'www.storiesevento.com.br', alignment: 'right', margin: [0, 0, 40, 0], fontSize: 10, color: '#dad0e9' }
            ]
        },
        info: {
            title: `Relatório de Uso - StoriesEvento - ${evento}`,
            author: 'StoriesEvento',
            subject: 'Dados e Registro de Envios',
            keywords: '',
            creator: 'StoriesEvento',
            producer: 'StoriesEvento',

        },
        watermark: {
            text: evento,
            color: '#c1b8d6',
            opacity: .05,
            bold: true,
            italics: false,
        },
        pageSize: pageSize,
        content: [
            {
                text: 'O StoriesEvento é uma plataforma inovadora que possibilita a interação e compartilhamento de momentos especiais durante eventos. Ao unir tecnologia e experiência, oferecemos uma forma única e dinâmica de criar histórias instantâneas por meio de fotos em tempo real. Este relatório reflete a participação e a energia capturadas durante o evento, encapsulando memórias significativas. Agradecemos por escolher o StoriesEvento para tornar seu evento ainda mais especial.',
                fontSize: 9,
                margin: [0, 5, 0, 15],
                italics: true,
                color: '#adadad',
                alignment: 'justify'
            },
            {
                table: {
                    widths: [375, 130],
                    body: [
                        [
                            {
                                text: [

                                    '\n',
                                    { text: evento, fontSize: 25, bold: true, color: '#35188f', margin: [0, 20, 0, 0] },
                                    '\n',
                                    { text: `Início: ${times[0].hora}h de ${times[0].dia}/${times[0].mes}/${times[0].ano} | Final: ${times[times.length - 1].hora}h de ${times[times.length - 1].dia}/${times[times.length - 1].mes}/${times[times.length - 1].ano}`, fontSize: 10, color: '#7a7a7a' },
                                    '\n',
                                    '\n',
                                    '\n',
                                    {
                                        text: `Números do Evento`, fontSize: 10, bold: true, margin: [0, 80, 0, 5]
                                    },
                                    '\n',
                                    '\n',
                                    {
                                        text: numeroEnvios > 1 ? ` ${numeroEnvios} fotos enviadas ` : numeroEnvios === 1 ? ` ${numeroEnvios} foto enviada ` : 'Sem fotos enviadas', bold: true, background: '#8d40ff', color: '#ffffff', fontSize: 25,
                                    },
                                    '\n',
                                    '\n',
                                    {
                                        text: numeroDownloads > 1 ? ` ${numeroDownloads} downloads ` : ` Sem downloads `, bold: true, background: '#ff0f53', color: '#ffffff', fontSize: 25
                                    },
                                    '\n',
                                    '\n',
                                    {
                                        text: numeroShare > 1 ? ` ${numeroShare} compartilhamentos ` : ` Sem compartilhamentos `, bold: true, background: '#0fffa7', color: '#261068', fontSize: 25
                                    },
                                ]
                            },
                            {
                                stack: [
                                    { image: imagemAleatoriaComFrame, width: 130, alignment: 'center' },
                                    { text: 'Foto exemplo com moldura aplicada.', fontSize: 8, alignment: 'center', italics: true, color: '#adadad', margin: [0, 5, 0, 0] }
                                ]
                            },
                        ],
                    ],
                },
                layout: 'noBorders',
            },
            '\n',
            {
                image: await graficoLinha(
                    horaEnvio.map(o => o.hora + 'h'),
                    'Fotos enviadas', horaEnvio.map(o => o.totalFotos),
                    'Download', horaEnvio.map(o => o.totalDownload),
                    'Share', horaEnvio.map(o => o.totalShare)
                ),
                width: 517,
            },
            {
                text: `Registro de Envios`, fontSize: 10, bold: true, margin: [0, 30, 0, 35]
            },
            {

                fontSize: 8,
                color: '#7a7a7a',
                table: {
                    widths: [30, ladoQuadrado, 100, '*', 60, 60],
                    headerRows: 1,
                    body: [
                        [{ text: 'Horário', bold: true }, { text: 'Foto', bold: true }, { text: 'Nome', bold: true }, { text: 'Legenda', bold: true }, { text: 'Download', bold: true }, { text: 'Share', bold: true },],
                        ...tabelaBody
                    ]
                },
                layout: {
                    hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 2 : 1,
                    vLineWidth: (i) => 0,
                    hLineColor: function (i, node) {
                        return '#eae7f2';
                    }
                }
            }
        ]
    };

    const options = {};

    const pdfBlobPromise = new Promise((resolve, reject) => {
        const pdfBuffer = printer.createPdf(docDefinition, options).getBuffer((buffer) => {
            const pdfBlob = new Blob([buffer], { type: 'application/pdf' });
            resolve(pdfBlob);
        });
    });

    const pdfBlob = await pdfBlobPromise;
    return URL.createObjectURL(pdfBlob);
}