import React, { useEffect, useState } from 'react';
import { pegaAPI } from '../../services/stories/postContentService';
import Slideshow from '../../components/Slideshow/Slideshow';
import Publi from '../../components/Publi/Publi';
import gifNextPhoto from '../../images/gif-next-photo.gif';
import VisitantesDefault from './VisitantesDefault';
import AssinaturaEvento from '../../components/AssinaturaEvento/AssinaturaEvento';
import MosaicFront from './Mosaic';

/**
 * props 
 * - endpoint
 * - evento
 * - tempo
 * - quantidade
 * - showQr
 * - aprova
 * - exclui
 */
function Stories(props) {

    const [endpoint, setEndpoint] = useState('');
    const [evento, setEvento] = useState('');
    const [api, setApi] = useState([]);
    const [apiPubli, setApiPubli] = useState({});
    const [aprova, setAprova] = useState({});
    const [exclui, setExclui] = useState({});
    const [showQr, setShowQr] = useState(true);
    const [tempoQuantidade, setTempoQuantidade] = useState({
        tempo: props.tempo * 1000,
        quantidade: props.quantidade
    })
    const [totalEntries, setTotalEntries] = useState(0);
    const [idxInsercao, setIdxInsercao] = useState([]);
    const [idxPubli, setIdxPubli] = useState(0);
    const [publiComplete, setPubliComplete] = useState(false);

    useEffect(() => {
        if (endpoint && endpoint.length > 0) pegaApiFunc('stories')
        pegaApiFunc('publi')
    }, [endpoint])

    useEffect(() => {
        if (props && props.endpoint) setEndpoint(props.endpoint)
        if (props && props.evento) setEvento(props.evento)
        let changedTQ = false;
        if (tempoQuantidade.tempo !== (props.tempo * 1000)) {
            tempoQuantidade.tempo = props.tempo * 1000;
            changedTQ = true;
        }
        if (tempoQuantidade.quantidade !== props.quantidade) {
            tempoQuantidade.quantidade = props.quantidade;
            changedTQ = true;
        }
        if (changedTQ) setTempoQuantidade({ ...tempoQuantidade });
        if (showQr !== props.showQr) setShowQr(props.showQr);
        if (aprova !== props.aprova) {
            if (aprova.media && aprova.media === props.aprova.media) return;
            setAprova(props.aprova);
        }
        if (exclui !== props.exclui) setExclui(props.exclui);
    }, [props])

    useEffect(() => {
        for (let i of api) {
            if (Object.keys(i).length === 0) {
                return setApi(api.filter(o => Object.values(o).some(value => value !== '')))
            }
        }
    }, [api])

    useEffect(() => {
        if (aprova && api && api.length > 0) {
            if (api.find(o => o.horario === parseInt(aprova.horario))) return;
            else return setApi(prevState => [...prevState, aprova]);
        }
    }, [aprova])

    useEffect(() => {
        if (exclui && api && api.length > 0) {
            const idx = api.findIndex(o => o.id === parseInt(exclui.id));
            if (idx !== -1) {
                const novo = api.filter(o => o.id !== exclui.id);
                setApi(novo)
            }
            else return;
        }
    }, [exclui])

    useEffect(() => {
        const interval = setInterval(() => {
            setTotalEntries((prevCounter) => prevCounter + 1);
        }, props.tempo * 1000);
        return () => clearInterval(interval);
    }, []);

    function pegaApiFunc(src) {
        if (src === 'stories') {
            pegaAPI('stories', false, endpoint)
                .then(res => {
                    if (endpoint === 'visitantes') {
                        const defaultVisitors = VisitantesDefault();
                        if (res.length > 0 && res.length < 3) {
                            res = Array.from(res);
                            res.push(defaultVisitors[2]);
                        }
                        if (res.length === 0) res = defaultVisitors;
                    }
                    if (res) {
                        const quantidadeDesejada = tempoQuantidade.quantidade;
                        for (let i = Math.max(res.length - quantidadeDesejada, 0); i < res.length; i++) {
                            const imageMedia = new Image();
                            imageMedia.src = res[i].media;
                        }

                        for (let i = Math.max(res.length - quantidadeDesejada, 0); i < res.length; i++) {
                            const avatarMedia = new Image();
                            avatarMedia.src = res[i].avatar;
                        }

                        if (res.length > quantidadeDesejada) {
                            setApi(res.slice(Math.max(res.length - quantidadeDesejada, 0)));
                        } else {
                            setApi(res);
                        }
                    }
                })
                .catch(err => console.error(err));
        }
        if (src === 'publi') {
            pegaAPI('publi')
                .then(res => {
                    setApiPubli(res)
                })
                .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        if (api) {
            if (totalEntries > api.length - 1) {
                if (api.length > tempoQuantidade.quantidade) {
                    const excedentes = api.length - tempoQuantidade.quantidade;
                    for (let i = 1; i <= excedentes; i++) {
                        if (api[0].played >= 2) {
                            api.shift();
                            setApi([...api]);
                        }
                    }
                }
                setTotalEntries(0);
            }
        }
    }, [totalEntries])

    useEffect(() => {
        if (idxPubli > apiPubli.length - 1) return setIdxPubli(0);
    }, [idxPubli])

    useEffect(() => {
        if (publiComplete) {
            setIdxPubli((prevCounter) => prevCounter + 1);
        }
    }, [publiComplete])

    useEffect(() => {
        if (idxInsercao === totalEntries && !publiComplete) {
            const idx = totalEntries;
            setTotalEntries(idx);

            setTimeout(() => {
                setTotalEntries(idx);
                setPubliComplete(true);
            }, props.tempo * 1000)
        }
        if (totalEntries === api.length) setPubliComplete(false);
    }, [totalEntries])

    function injectPubli(idx) {
        return (
            <Publi key={apiPubli[idx].horario} publi={apiPubli[idx]} tempo={props.tempo * 1000} />
        )
    }

    useEffect(() => {
        if (api && api.length > 0 && apiPubli && apiPubli.length > 0) {
            setIdxInsercao(Math.round(api.length / 2))
        }
    }, [apiPubli])

    function NextPhotoName() {

        const diff = api.length - tempoQuantidade.quantidade;

        let proxNomeSeExcedeu =
            tempoQuantidade.quantidade < api.length
                ? api[api.length - tempoQuantidade.quantidade].played < 2 ? api[api.length - tempoQuantidade.quantidade - diff].name : api[api.length - tempoQuantidade.quantidade].name
                : null;

        return (
            <div className='NextPhotoName'>
                {
                    api && api.length > 0
                        ? endpoint === 'visitantes'
                            ? api[totalEntries + 1] === api[api.length - 1] && !api[api.length - 1].name
                                ? <></>
                                : (
                                    <div>
                                        A seguir:&nbsp;<span>
                                            {api[totalEntries + 1 === api.length || totalEntries === api.length ? 0 : parseInt(totalEntries) + 1].name}
                                        </span>
                                        <img src={gifNextPhoto} />
                                    </div>
                                )
                            : (
                                <div>
                                    A seguir:&nbsp;<span>
                                        {
                                            tempoQuantidade.quantidade < api.length && totalEntries + 1 === api.length
                                                ? proxNomeSeExcedeu
                                                : api[totalEntries + 1 === api.length || totalEntries === api.length ? 0 : parseInt(totalEntries) + 1].name
                                        }
                                    </span>
                                    <img src={gifNextPhoto} />
                                </div>
                            )
                        : null
                }
            </div>
        )
    }

    function playedSlide(data) {
        setApi(prevApi => {
            const updatedApi = [...prevApi];
            const index = updatedApi.findIndex(o => o.id === data);

            if (index !== -1) {
                updatedApi[index] = { ...updatedApi[index] };
                if (updatedApi[index].played && updatedApi[index].played >= 1) updatedApi[index].played += 1;
                else updatedApi[index].played = 1;
            }

            return updatedApi;
        });
    }

    return (
        <main>
            {
                apiPubli && apiPubli.length > 0 && idxInsercao === totalEntries && !publiComplete
                    ? injectPubli(idxPubli)
                    : (
                        <div className='basePost'>
                            {
                                api && api.length > 0
                                    ? <Slideshow endpoint={endpoint} evento={evento} data={api[totalEntries < api.length ? totalEntries : 0]} tempo={tempoQuantidade.tempo} showQr={showQr} played={playedSlide} />
                                    : <React.Fragment></React.Fragment>
                            }
                            {AssinaturaEvento(evento)}
                            <div className="timerBar">
                                {
                                    api && api.length > 0
                                        ? Array.from({ length: api.length }).map((curr, idx) => (
                                            <div key={`bar-` + idx} className="baseProgress">
                                                <div className={`progressBar ${idx === totalEntries ? 'animaBar' : ''} ${idx < totalEntries ? 'filledProgressBar' : ''}`} style={idx === totalEntries ? { animationDuration: props.tempo * 1000 + 'ms' } : null}></div>
                                            </div>
                                        ))
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                            <NextPhotoName />
                        </div>
                    )
            }
        </main>
    )
}

export default Stories;
