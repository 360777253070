import React from "react";
import GifComecar from '../../images/gif-envio-comecar-storiesevento.gif';
//import GifBlackComecar from '../../images/gif-envio-black-woman-comecar-storiesevento.gif';
import Assinatura from "../Assinatura/Assinatura";

/**
 * props 
 * - callbackParent
 */
function ModalComecar(props) {
    
    function comecar() {
        props.callbackParent();
    }

    return (
        <div>
            <div className="modalComecar fadein">
            <Assinatura aplicativo='stories'/>
                <div>
                    <img src={GifComecar} alt="Envie seu Story!" />
                </div>
                <div className="texto">
                    Compartilhe aqui seu <span className="melhor">melhor</span> momento!
                </div>
                <div>
                    <button onClick={comecar} className='comecar' type='button'>Começar</button>
                </div>
            </div>
            <div className="blur"></div>
        </div>
    )
}

export default ModalComecar;
