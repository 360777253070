module.exports = function (miliseconds, format) {
    let d = new Date(miliseconds);
    let iso = d.getFullYear().toString() + "-";
    iso += (d.getMonth() + 1).toString().padStart(2, '0') + "-";
    iso += d.getDate().toString().padStart(2, '0') + "T";
    iso += d.getHours().toString().padStart(2, '0') + ":";
    iso += d.getMinutes().toString().padStart(2, '0') + ":";
    iso += d.getSeconds().toString().padStart(2, '0');

    const diffHrs = Math.floor((miliseconds % 86400000) / 3600000);
    const diffMins = Math.round(((miliseconds % 86400000) % 3600000) / 60000);
    const diff = diffHrs + 'h ' + diffMins + 'min';

    if (format === 'data') return `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getFullYear().toString()}`;
    if (format === 'hora') return `${d.getHours().toString().padStart(2, '0')}h${d.getMinutes().toString().padStart(2, '0')}`;
    if (format === 'duracao') return diff;
}