import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
Chart.defaults.font.size = 30;
export function graficoLinha(labels, label1, data1, label2, data2, label3, data3) {
    
    return new Promise(resolve => {
        const canvas = document.createElement('canvas');
        document.body.appendChild(canvas);

        canvas.width = 517;
        canvas.height = 400 ;

        const ctx = canvas.getContext('2d');

        const lineChartData = {
            labels: labels,
            datasets: [
                {
                    label: label1,
                    borderColor: '#8d40ff',
                    backgroundColor: '#8d40ff',
                    borderWidth: 2,
                    fill: false,
                    data: data1
                },
                {
                    label: label2,
                    borderColor: '#ff0f53',
                    backgroundColor: '#ff0f53',
                    borderWidth: 2,
                    fill: false,
                    data: data2
                },
                {
                    label: label3,
                    borderColor: '#0fffa7',
                    backgroundColor: '#0fffa7',
                    borderWidth: 2,
                    fill: false,
                    data: data3
                }
            ]
        };

        const lineChartOptions = {
            plugins: {
                title: {
                    display: true,
                    text: 'Gráfico de Envios por Hora',
                    padding: {
                        top: 10,
                        bottom: 30
                    }
                }
            },
            scales: {
                y: {
                    type: 'linear',
                    beginAtZero: true,
                }
            },
            animation: {
                onComplete: () => done().then(resolve)
            }
        };

        const myLineChart = new Chart(ctx, {
            type: 'line',
            data: lineChartData,
            options: lineChartOptions
        });

        function done() {
            return new Promise(innerResolve => {
                const base64Image = canvas.toDataURL('image/png');
                document.body.removeChild(canvas);
                innerResolve(base64Image);
            });
        }
    });
}
