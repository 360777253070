import React, { useEffect, useState, useRef } from "react";
import Share from "../Share/Share";

/**
 * props 
 * - endpoint
 * - evento
 * - nome
 * - media
 * - frame
 */
function FramePhoto(props) {

    const canvasRef = useRef()
    const btnDownload = useRef();

    const [endpoint, setEndpoint] = useState('');
    const [media, setMedia] = useState('');
    const [evento, setEvento] = useState('');
    const [nome, setNome] = useState('');
    const [frame, setFrame] = useState('');
    const [link, setLink] = useState('#');

    useEffect(() => {
        setEndpoint(props.endpoint);
        setMedia(props.media);
        setFrame(props.frame);
        setEvento(props.evento);
        setNome(props.nome);
    }, [])

    useEffect(() => {
        if (media && frame && media.length > 0 && frame.length > 0) {
            const context = canvasRef.current.getContext("2d");
            canvasRef.current.width = 675;
            canvasRef.current.height = 1200;

            const photo = new Image();
            photo.crossOrigin = '*';
            photo.src = media;
            photo.onload = () => {

                var scale = Math.max(canvasRef.current.width / photo.width, canvasRef.current.height / photo.height);

                var x = (canvasRef.current.width / 2) - (photo.width / 2) * scale;
                var y = (canvasRef.current.height / 2) - (photo.height / 2) * scale;
                context.drawImage(photo, x, y, photo.width * scale, photo.height * scale);

                const moldura = new Image();
                moldura.crossOrigin = '*';
                moldura.src = frame;
                moldura.onload = () => {
                    context.drawImage(moldura, 0, 0, canvasRef.current.width, canvasRef.current.height);
                }
            }
        }
    }, [media, frame])

    function download() {
        props.capturaClique('download')
        let image = canvasRef.current.toDataURL('image/jpeg');
        const date = new Date();
        btnDownload.current.download = `${evento.toLowerCase().replaceAll(' ', '-')}-${nome.toLowerCase().replaceAll(' ', '-')}-${date.toLocaleDateString().replaceAll('/', '-')}-${date.toLocaleTimeString().slice(0, -3).replace(':', 'h')}`;
        setLink(image);
    }

    function shared() {
        return props.capturaClique('shared')
    }

    return (
        <div className="frame">
            <div className='canvasBox'>
                <canvas ref={canvasRef} />
            </div>
            <div className="downloadShare">
                <div className='downloadPhoto'>
                    <a href={link} ref={btnDownload} onClick={download}>
                        Baixar foto
                        <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                        </svg>
                    </a>
                </div>
                <Share endpoint={endpoint} evento={evento} nome={nome} imagem={canvasRef.current} onShareClick={shared} />
            </div>
        </div>
    )
}

export default FramePhoto;