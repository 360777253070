import React, { useEffect, useState, useRef } from "react";
import { aprovar, excluir } from "../../services/stories/adminService";
import { AVATAR_FILES } from "../..";
import LazyPhoto from "../LazyPhoto/LazyPhoto";
import avatarThumb from '../../images/avatar.jpg';

/**
 * props 
 * - endpoint
 * - media
 * - name
 * - ident
 * - avatar
 * - legenda
 * - horário
 * - disabled
 */
function CardPainel(props) {

    const btnAprova = useRef();
    const btnExclui = useRef();

    const [endpoint, setEndpoint] = useState('');
    const [display, setDisplay] = useState('block');
    const [files, setFiles] = useState();
    const [card, setCard] = useState({
        id: 0,
        name: '',
        ident: '',
        avatar: '',
        media: '',
        legenda: '',
        horario: 0,
        pendente: ''
    })

    useEffect(() => {
        if (props) {
            setCard({
                id: props.id,
                name: props.name,
                ident: props.ident,
                avatar: props.avatar <= 15 && files ? files[props.avatar] : props.avatar,
                media: props.media,
                legenda: props.legenda,
                horario: timestamp(props.horario),
                pendente: props.pendente
            })
        }
    }, [files])

    useEffect(() => {
        if (props.endpoint && props.endpoint.length > 0) setEndpoint(props.endpoint);
    }, [props])

    useEffect(() => {
        if (files && files.length > 0) return;
        setFiles(AVATAR_FILES());
    }, [])

    function timestamp(mili) {
        const data = new Date(mili);
        const miliAgora = Date.now();
        const agora = new Date(miliAgora);
        if (agora - data < 86400000) {
            const horasAtras = Math.floor((agora - data) / 3600000);
            const minutosAtras = Math.floor((agora - data) / 60000);
            if (horasAtras >= 1) return `Há ${horasAtras} ${horasAtras === 1 ? 'hora' : 'horas'}`
            else if (minutosAtras < 1) return 'Agora';
            else return `Há ${minutosAtras} ${minutosAtras === 1 ? 'minuto' : 'minutos'}`;
        }
        let dia = data.getDate().toString().padStart(2, '0');
        let mes = (data.getMonth() + 1).toString().padStart(2, '0');
        let ano = data.getFullYear();
        return dia + "/" + mes + "/" + ano;
    }

    function aprovaCard(e) {
        const token = localStorage.getItem('token');
        const id = e.target.id;
        aprovar(id, token, endpoint)
            .then(res => res ? btnAprova.current.className = 'aprovado' : null)
            .catch(err => console.error(err));
    }

    function excluiCard(e) {
        const token = localStorage.getItem('token');
        const id = e.target.id;
        window.confirm(`Excluir o post de ${card.name}?`)
            ? excluir(id, token, endpoint)
                .then(res => {
                    if (res) btnExclui.current.className = 'excluido';
                    setDisplay('none');
                })
                .catch(err => console.error(err))
            : console.log('Post não excluído')
    }

    return (
        <div className="cardPainel" style={{ display: display }}>
            <div className="itens">
                <div className="idCard"># <strong>{card.id}</strong></div>
                <div className="imgCard">
                    {
                        card && card.legenda && card.legenda.length > 0
                            ? (
                                <div className="legendaCard">
                                    {card.legenda}
                                </div>
                            )
                            : <React.Fragment></React.Fragment>
                    }
                    <LazyPhoto src={card.media} alt={`Imagem de ${card.name}`} />
                    {/* <img src={card.media} alt={`Imagem de ${card.name}`} /> */}
                </div>
                <div className="infoCard">
                    <div className="avatarCard">
                        <img src={card.avatar ? card.avatar : avatarThumb} alt={`Avatar de ${card.name}`} />
                    </div>
                    <div className="textoCard">
                        <h5>{card.name}</h5>
                        <div>{card.horario}</div>
                    </div>
                </div>
            </div>
            <div className="gerencia">
                <button id={card.id} ref={btnAprova} className={card.pendente === 's' ? 'aprovar' : 'aprovado'} type="button" onClick={aprovaCard} disabled={props.disabled}>Aprovar</button>
                <button id={card.id} ref={btnExclui} className={card ? 'excluir' : 'excluido'} type="button" onClick={excluiCard} disabled={props.disabled}>Excluir</button>
            </div>
        </div>
    )
}

export default CardPainel;