import React, { useState, useRef, useEffect } from 'react';
import { login } from '../../services/authService';
import { useParams, useNavigate } from 'react-router-dom';
import Assinatura from '../../components/Assinatura/Assinatura';
import ReCAPTCHA from "react-google-recaptcha";

function Auth() {
    const url = useParams();
    let navigate = useNavigate();
    const loginRef = useRef();
    const pwdRef = useRef();
    const recaptchaRef = useRef();

    const [evento, setEvento] = useState('');
    const [tkRC, setTkRc] = useState('');
    const [error, setError] = useState('');

    function onSubmit(event) {
        event.preventDefault();
        if (loginRef.current.value.length > 0) {
            event.target.innerHTML = '<span class="loader"></span>';
            recaptchaRef.current.execute();
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit(event);
        }
    };
    
    useEffect(() => {
        if (url.dynamicRoute) setEvento(url.dynamicRoute);
        else return;
    }, [])

    useEffect(() => {
        if (tkRC && tkRC.length > 0) {
            login(loginRef.current.value, pwdRef.current.value, evento)
                .then(res => {
                    if (res) {
                        localStorage.setItem('token', res.token);
                        return navigate('/stories/admin/' + evento)
                    }
                    else console.log(res.status)
                })
                .catch(err => {
                    console.error(err);
                    setError('Usuário e/ou senha inválidos!');
                    setTimeout(() => window.location.reload(false), 2500);
                })
        }
    }, [tkRC])

    function onChangeRC(e) {
        setTkRc(e)
    }

    return (
        <main className='App'>
            <div className='authBox'>
                <div className='titleSend'>
                    <h1>Administração</h1>
                </div>
                <div className='nome'>
                    <input type='text' ref={loginRef} placeholder="Usuário" required />
                </div>
                <div className='ident'>
                    <input type='password' ref={pwdRef} placeholder="Senha" onKeyDown={handleKeyDown} required />
                </div>

                <div className='botaoSend'>
                    <button type='button' onClick={onSubmit}>Acessar</button>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={onChangeRC}
                    />
                </div>
                {
                    error
                        ? <div className="erroLogin">{error}</div>
                        : <React.Fragment></React.Fragment>
                }

            </div>
            <Assinatura aplicativo='stories' />
        </main>
    )
}

export default Auth;