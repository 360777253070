import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import App from "./App";
import Envio from './public/Stories/Envio/Envio';
import Auth from './public/Stories/Auth';
import Admin from "./admin/stories/Admin";
import AdminSingle from "./admin/stories/AdminSingle";
import MosaicFront from "./public/Stories/Mosaic";
import Home from "./public/Stories/Home";

function Rotas() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/stories/admin" element={<Admin />} />
                <Route path="/stories/admin/:dynamicRoute" element={<AdminSingle />} />
                <Route path="/stories/login/:dynamicRoute?" element={<Auth />} />
                <Route path="/stories/:dynamicRoute" element={<App />} exact />
                <Route path="/mosaic/:dynamicRoute" element={<MosaicFront />}  />
                <Route path="/" element={<Home />} exact />
                <Route path="/stories/envio/:dynamicRoute" element={<Envio />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;