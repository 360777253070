import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

/**
 * props 
 * - src
 * - alt
 */
function LazyPhoto(props) {

    const [style, setStyle] = useState('');

    useEffect(() => {
        setStyle('fadein');
        setTimeout(() => setStyle(''), 2000)
    }, [props.src])

    return (
        <div>
            <LazyLoadImage className={style} effect="blur" alt={props.alt} src={props.src} />
        </div>
    );
}

export default LazyPhoto;