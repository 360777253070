import React, { useState, useEffect } from "react";
import Assinatura from "../Assinatura/Assinatura";
import GifOpenComecar from '../../images/gif-open-comecar-storiesevento.gif';
//import GifSlidesOpenComecar from '../../images/gif-slides-openstories.gif';
import QRCodeGen from "../QRcodeGen/QRcodeGen";
//import GifOpenBlackComecar from '../../images/gif-open-black-woman-comecar-storiesevento.gif';

/**
 * props 
 * - evento
 * - endpoint
 */
function OpenStories(props) {

    const [evento, setEvento] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [tempoQuantidade, setTempoQuantidade] = useState({
        tempo: 10000
    })
    const [flag, setFlag] = useState(false);
    const [qrImage, setQrImage] = useState();
    const [gifImage, setGifImage] = useState();
    const [styles, setStyles] = useState('fadein');

    useEffect(() => {
        const gif = new Image();
        gif.src = GifOpenComecar;
        setGifImage(gif);
    }, [])

    useEffect(() => {
        if (evento) {
            const url = process.env.REACT_APP_FRONT_URL + `/stories/envio/${endpoint}`;
            const size = 240;
            setQrImage(QRCodeGen({ url, size }));
        }
    }, [evento])

    useEffect(() => {
        if (!evento && props && props.evento) setEvento(props.evento);
        if (!endpoint && props && props.endpoint) setEndpoint(props.endpoint);
    }, [props])

    useEffect(() => {
        setTimeout(() => setStyles('fadeout'), tempoQuantidade.tempo - 200)
        setStyles('fadein')
    }, [flag])

    useEffect(() => {
        setTimeout(() => {
            flag ? setFlag(false) : setFlag(true);
        }, tempoQuantidade.tempo)
    }, [flag])

    function about() {
        return (
            <div className={`openAbout ${styles}`} style={{ display: !flag ? 'none' : 'block' }}>
                <h2>Compartilhe aqui a sua Foto!</h2>
                <p>Acesse o QR code abaixo<br />e participe!</p>
                <div className="tagQr">
                    <div style={{ borderRadius: '10px', display: 'inline-flex', width: 'auto', padding: '20px', backgroundColor: '#fff' }}>
                        {qrImage}
                    </div>
                </div>
                <Assinatura aplicativo='stories' />
            </div>
        )
    }

    function animatedSlide() {
        return (
            <div className={`openAbout ${styles}`}>
                {evento && evento.length > 0 && (<span className="nomeEventoOpen">{evento.toUpperCase()}</span>)}
                <img style={{ width: '100%' }} src={gifImage.src} alt="Envie sua Foto!" />
                <Assinatura aplicativo='stories' />
            </div>
        )
    }

    function logoScreen() {
        return (
            <div className={`context ${styles}`}>
                <h1><span>stories</span>evento</h1>
            </div>
        )
    }

    return (
        <div className="OpenStories fadein">
            {about()}
            {
                gifImage && gifImage.src
                    ? !flag
                        ? evento ? animatedSlide() : logoScreen()
                        : evento ? about() : animatedSlide()
                    : <></>
            }
            <div className="area" >
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    )
}

export default OpenStories;