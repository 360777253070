import React from "react";

/**
 * props 
 * - endpoint
 * - evento
 * - nome
 * - imagem
 */
function Share(props) {
    const evento = props.evento.toUpperCase();
    const url = 'https://www.storiesevento.com.br/stories/envio/' + props.endpoint;
    async function shareCanvas() {
        const canvasElement = props.imagem
        const dataUrl = canvasElement.toDataURL();
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray = [
            new File(
                [blob],
                `${props.evento}-${props.nome}.png`,
                {
                    type: blob.type,
                    lastModified: new Date().getTime()
                }
            )
        ];
        const shareData = {
            files: filesArray,
            url: url,
            title: evento,
            text: `* ${evento} * \n Participando através do StoriesEvento! \n`
        };
        props.onShareClick();
        navigator.share(shareData);
    }

    return (
        <div>
            {
                props && props.imagem
                    ? (
                        <button
                            type="button"
                            className='share'
                            onClick={shareCanvas}>
                            Compartilhar
                            <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                            </svg>
                        </button>
                    )
                    : <React.Fragment></React.Fragment>
            }
        </div>

    )
}

export default Share;