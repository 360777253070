import React from "react";

function AnimaBackground() {
    return (
        <div className="aniBg">
            <div className="OpenStories fadein">
                <div className="area" >
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
            <div className='linearFade'></div>
        </div>
    )
}

export default AnimaBackground;