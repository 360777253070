import React from "react";
import QRCodeGen from "../QRcodeGen/QRcodeGen";

function TagQR({ endpoint, evento }) {
    const url = process.env.REACT_APP_FRONT_URL + '/stories/envio/' + endpoint;
    const size = window.innerHeight/8;
    return (
        <div className="tagQr">
            <div className="labelQr"><p>Envie sua Foto</p></div>
            <div>
                {QRCodeGen({ url, size })}
            </div>
        </div>
    )
}

export default TagQR;