import React, { useEffect, useState } from "react";

export default function AssinaturaEvento(nomeEvento) {

    const [evento, setEvento] = useState('');

    useEffect(() => {
        setEvento(nomeEvento)
    }, [nomeEvento])

    return (<div className="AssinaturaEvento"><span className="text">{evento}</span></div>)
}