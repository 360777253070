import React, { useState, useEffect, useRef } from "react";
import { sendUserUpdate, trocaFrame, encerrarEvento, limpaTudo, downloadPhotos } from "../../services/stories/adminService";
import { pegaAPI } from "../../services/stories/postContentService";
import Resizer from "react-image-file-resizer";
import Relatory from "../Relatory/Relatory";
import Time from "../Time/Time";

/**
 * props 
 * usuario
 * evento
 * frame
 * inicio
 * senha
 */
export default function ItemListaEventos(props) {

    const senhaRef = useRef();
    const btnDownloadRelatory = useRef();

    const [evento, setEvento] = useState('');
    const [usuario, setUsuario] = useState('');
    const [temSenha, setTemSenha] = useState(false);
    const [endpoint, setEndpoint] = useState('');
    const [frame, setFrame] = useState();
    const [novoFrame, setNovoFrame] = useState(); 
    const [inicio, setInicio] = useState(0);
    const [userConfig, setUserConfig] = useState(false);
    const [urlAdmin, setUrlAdmin] = useState('');
    const [linkRelatory, setLinkRelatory] = useState('#');
    const [countEvento, setCountEvento] = useState({
        hora: 0,
        minuto: 0,
        segundo: 0
    });
    const [clicouFim, setClicouFim] = useState(false);

    useEffect(() => {
        if (props) {
            setEvento(props.evento);
            setUsuario(props.usuario);
            setTemSenha(props.senha);
            setFrame(props.frame);
            setInicio(props.inicio);
        }
    }, [props]);

    useEffect(() => {
        if (evento && evento.length > 0 && inicio > 0) {
            const endpoint = `${evento.toLowerCase().replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '')}-${Time(inicio, 'data')}-${Time(inicio, 'hora')}`;
            setEndpoint(endpoint);
            setUrlAdmin(`${process.env.REACT_APP_FRONT_URL}/stories/admin/${endpoint}`);
        }
    }, [evento])

    useEffect(() => {
        if (inicio > 0) {
            const duracao = Date.now() - inicio;
            let segundo = parseInt((Math.trunc((duracao / 1000).toFixed(1)))) % 60;
            let minuto = parseInt((Math.trunc((duracao / (1000 * 60)).toFixed(1)))) % 60;
            let hora = (duracao / (1000 * 60 * 60)).toFixed(1);
            countEvento.segundo = segundo;
            countEvento.minuto = minuto;
            countEvento.hora = Math.trunc(hora);
            setCountEvento({ ...countEvento });
        }
    }, [inicio])

    useEffect(() => {
        if (inicio > 0) {
            const interval = setInterval(() => {
                countEvento.segundo = parseInt(countEvento.segundo) + 1;
                if (countEvento.segundo === 60) {
                    countEvento.minuto = parseInt(countEvento.minuto) + 1;
                    countEvento.segundo = 0;
                }
                if (countEvento.minuto === 60) {
                    countEvento.hora = parseInt(countEvento.hora) + 1;
                    countEvento.minuto = 0;
                }
                setCountEvento({ ...countEvento });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [inicio, countEvento.segundo])

    useEffect(() => {
        if (novoFrame) {
            const token = localStorage.getItem('token');
            trocaFrame(evento, novoFrame, endpoint, token)
            .then(res => {
                if (res) window.location.reload();
                else window.alert('erro na troca de frame')
            })
        }
    }, [novoFrame])

    async function onImage(event) {
        if (event.target.files && event.target.files[0]) {
            await resizeFile(event.target.files[0]);
        }
    }

    function resizeFile(file) {
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                675,
                1200,
                "PNG",
                90,
                0,
                (uri) => {
                    setNovoFrame(uri)
                    resolve(uri);
                },
                "base64"
            );
        });
    }

    function handleFinalizar(event) {
        event.target.innerHTML = '<span class="loader"></span>';
        return setTimeout(() => setClicouFim(true), 1500);
    }

    function confirmaFim() {
        if (clicouFim) {
            const token = localStorage.getItem('token');
            encerrarEvento(token, { evento, frame, inicio }, endpoint)
                .then(res => {
                    // tem que ver  um dispositivo para ir limpando os arquivos de visitantes/
                    // colocar gestão de visitantes na tela de admin
                    // ajustar o css de todas as paginas
                    // admin e adminSingle responsivos
                    // coisas
                    limpaTudo(token, endpoint)
                        .then(res => {
                            if (res) {
                                setTimeout(() => window.localStorage.setItem('token', ''), 1000)
                                window.location.reload();
                            }
                        })
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err))
        }

        setClicouFim(false)
    }

    function userEdit() {
        setUserConfig(true);
    }

    function genPassword() {
        const date = new Date();
        let momentoStr = date.getTime().toString();
        let pwdTsf = [];
        for (let i = 0; i < momentoStr.length; i++) {
            pwdTsf.push(String.fromCharCode(parseInt(momentoStr[i]) + 64))
        }
        return `${evento.slice(0, 3)}${pwdTsf.toString().replaceAll(',', '')}`.slice(0, -3);
    }

    function userUpdate() {
        const senha = senhaRef.current.value;
        const token = localStorage.getItem('token');
        if (usuario && senha && token) {
            sendUserUpdate(usuario, senha, endpoint, token)
                .then(res => window.location.reload())
                .catch(err => console.error(err))
        }
        else setUserConfig(false);
    }

    async function handleDownloadPhotos(event) {
        event.target.innerHTML = '<span class="loader"></span>';
        const token = localStorage.getItem('token');
        await downloadPhotos(frame, endpoint, token)
            .then(res => window.open(res, '_blank'))
        event.target.innerHTML = 'Fotos.zip';
    }

    async function downloadRelatory(event) {
        event.target.innerHTML = '<span class="loader"></span>';
        await pegaAPI('stories', true, endpoint)
            .then(async api => {
                await Relatory(evento, frame, api)
                    .then(res => {
                        if (res) {
                            setLinkRelatory(res);
                            const date = new Date();
                            btnDownloadRelatory.current.download = `relatorio-${evento.toLowerCase().replaceAll(' ', '-')}-${date.toLocaleDateString().replaceAll('/', '-')}-storiesevento.pdf`;
                            return setTimeout(() => {
                                btnDownloadRelatory.current.click();
                                window.location.reload();
                            }, 2000)
                        }
                    })
            })
            .catch(err => console.error(err))
    }

    return (
        <div className="itemEvento">
            <div className="evento">{evento}</div>
            {
                userConfig
                    ? (<div>
                        <div>
                            <label>Usuário</label>
                            <span>{usuario}</span>
                        </div>
                        <div>
                            <label>{temSenha ? 'Nova Senha' : 'Senha sugerida'}</label>
                            {
                                temSenha
                                    ? (<input ref={senhaRef} type="password" maxLength={20}></input>)
                                    : (<input ref={senhaRef} type="text" maxLength={20} defaultValue={genPassword()}></input>)
                            }
                        </div>
                        <button onClick={userUpdate} type="button">Salvar</button>
                        <button type="button" onClick={() => setUserConfig(false)}>Cancelar</button>
                    </div>)
                    : (
                        <div className="itemEventoFlex">
                            {
                                frame && frame.length > 0
                                    ? (
                                        <div className="frame">
                                            <a target="_blank" href={frame}><img src={frame} /></a>
                                            <label><input type='file' required accept='image/*' onChange={onImage} />
                                                Trocar Moldura
                                            </label>
                                        </div>
                                    )
                                    : <></>
                            }
                            <div className="inicio">{Time(inicio, 'data')} às {Time(inicio, 'hora')}</div>
                            <div><button onClick={userEdit}>Usuário</button></div>
                            <div className="admin"><a target="_blank" href={urlAdmin}>Painel</a></div>
                            <div className="countEvento">
                                <span>
                                    {countEvento.hora < 10 ? `0${countEvento.hora}` : countEvento.hora}:
                                    {countEvento.minuto < 10 ? `0${countEvento.minuto}` : countEvento.minuto}:
                                    {countEvento.segundo < 10 ? `0${countEvento.segundo}` : countEvento.segundo}
                                </span>
                            </div>
                            <button type="button" onClick={handleDownloadPhotos}>Fotos.zip</button>
                            <button type="button" ><a href={linkRelatory} ref={btnDownloadRelatory} onClick={downloadRelatory}>Relatório</a></button>
                            {
                                clicouFim
                                    ? (<button className="encerrarEvento" onClick={confirmaFim} type="button">Confirmar término</button>)
                                    : <button className="encerrarEvento" onClick={handleFinalizar} type="button">Finalizar evento</button>
                            }
                        </div>
                    )
            }
        </div>
    )
}