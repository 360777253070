import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { pegaAPI } from "../../services/stories/postContentService";
import { getToken } from "../../services/authService";
import Resizer from "react-image-file-resizer";
import { iniciarEvento, consultaPower } from "../../services/stories/adminService";
import ItemListaEventos from "../../components/ItemListaEventos/ItemListaEventos";
import Assinatura from "../../components/Assinatura/Assinatura";

function Admin() {

    const eventoRef = useRef();
    let navigate = useNavigate();

    const [novoEvento, setNovoEvento] = useState({
        evento: '',
        frame: '',
        inicio: 0
    });
    const [control, setControl] = useState([]);
    const [flagIni, setFlagIni] = useState(false);
    const [frameEscolhido, setFrameEscolhido] = useState('');
    const [api, setApi] = useState({});
    const [publiApi, setPubliApi] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * control.quantidade;
        const lastPageIndex = firstPageIndex + control.quantidade;
        return Array.from(api).reverse().slice(firstPageIndex, lastPageIndex);
    }, [currentPage, api])

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            getToken(token)
                .then(res => res ? console.log('Login efetuado com sucesso.') : console.log(res.status))
                .catch(err => {
                    console.error(err);
                    window.localStorage.setItem('token', '');
                    return navigate('/stories/login');
                })
        }
        else return navigate('/stories/login');
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            consultaPower()
                .then(res => {
                    if (res) {
                        setControl(res);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [])

    function pegaApiFunc(src) {
        if (src === 'stories') {
            setApi({})
            pegaAPI('stories', true)
                .then(res => setApi(res))
                .catch(err => console.error(err))
        }
        if (src === 'publi') {
            pegaAPI('publi', true)
                .then(res => {
                    setPubliApi(res)
                })
                .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token.length > 0) {
            pegaApiFunc('stories')
            pegaApiFunc('publi')
        }
    }, [])

    async function onImage(event) {
        if (event.target.files && event.target.files[0]) {
            await resizeFile(event.target.files[0]);
            return setFrameEscolhido(event.target.files[0].name)
        }
    }

    function resizeFile(file) {
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                675,
                1200,
                "PNG",
                90,
                0,
                (uri) => {
                    novoEvento.frame = uri;
                    setNovoEvento({ ...novoEvento });
                    resolve(uri);
                },
                "base64"
            );
        });
    }

    function btnIniciarEvento() {
        return setFlagIni(true);
    }

    function iniciar(event) {
        if (eventoRef.current.value === '' || !novoEvento.frame) return;
        event.target.innerHTML = '<span class="loader"></span>';
        let nomeEvento = eventoRef.current.value.trim();
        novoEvento.evento = nomeEvento;
        novoEvento.inicio = (new Date()).getTime();
        novoEvento.aprova = 'auto';
        novoEvento.usuario = nomeEvento.toLowerCase().replace(/ /g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        setNovoEvento({ ...novoEvento });
        return enviaInicio(event);
    }

    function enviaInicio(event) {
        const token = localStorage.getItem('token');
        if (novoEvento.evento.length > 0) {
            iniciarEvento(novoEvento, token)
                .then(res => {
                    if (res) window.location.reload();
                })
                .catch(err => console.error(err))
        }
    }

    return (
        <main className="painel">
            <div className="container">
                <div className="headerPainel">
                    <h1>
                        Painel de Controle
                        <div className="painelIni">
                            {
                                !flagIni
                                    ? (<button className="iniciarEvento" type="button" onClick={btnIniciarEvento}>Iniciar evento</button>)
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                flagIni
                                    ? (
                                        <div className="dadosIni">
                                            <input type="text" placeholder="Nome do evento" ref={eventoRef} />
                                            <label><input type='file' required accept='image/*' onChange={onImage} />
                                                {
                                                    frameEscolhido
                                                        ? (<span>{frameEscolhido}</span>)
                                                        : (<>Moldura <span>675 x 1200 pixels</span></>)
                                                }
                                            </label>
                                            <button className="iniciarEvento" type="submit" onClick={iniciar}>Iniciar</button>
                                        </div>
                                    )
                                    : <React.Fragment></React.Fragment>
                            }
                        </div>
                    </h1>
                </div>
                <div className="tarjaStories">
                    <h2 className="titleStoriesAdmin">
                        Stories
                    </h2>
                </div>
                {/* <PubliForm publiApi={publiApi && publiApi.length > 0 ? publiApi : null} /> */}
                {
                    control && control.length > 0
                        ? control.map(item => (
                            <ItemListaEventos key={item.inicio} usuario={item.usuario} senha={item.token ? true : false} evento={item.evento} inicio={item.inicio} frame={item.frame} />
                        ))
                        : <></>
                }
                <Link to={process.env.REACT_APP_FRONT_URL + '/stories/admin/visitantes'} target='_blank'>Gerenciar VISITANTES</Link>
            </div>
            <Assinatura aplicativo='stories' />
        </main >
    )
}

export default Admin;