import React, { useState, useEffect, useRef, useMemo } from 'react';
import TagQR from '../TagQR/TagQR';
import Assinatura from '../Assinatura/Assinatura';
//import Hearts from '../Hearts/Hearts';
//import GifHearts from '../../images/gif-heart-up-hearts.gif'
import avatarThumb from '../../images/avatar.jpg';
import LazyPhoto from '../LazyPhoto/LazyPhoto';
import { AVATAR_FILES } from '../../';

/**
 * props 
 * - endpoint
 * - evento
 * - data
 * - tempo
 * - showQr
 * - played
 */
function Slideshow(props) {
    const divPostRef = useRef();

    const [endpoint, setEndpoint] = useState('');
    const [evento, setEvento] = useState('');
    const [played, setPlayed] = useState();
    const [files, setFiles] = useState();
    const [data, setData] = useState({});
    const [tempo, setTempo] = useState(0);
    const [trigger, setTrigger] = useState();
    const [showQr, setShowQr] = useState(true);
    const [avatarDraw, setAvatarDraw] = useState();

    useEffect(() => {
        if (files && files.length > 0) return;
        setFiles(AVATAR_FILES());
    }, []);

    useEffect(() => {
        if (props && props.data && props.data.id && played !== props.data.id) setPlayed(props.data.id)
        if (props && props.evento) setEvento(props.evento);
        if (props && props.endpoint) setEndpoint(props.endpoint);
    }, [props]);

    useEffect(() => {
        if (props) {
            setData(props.data);
            setShowQr(props.showQr);
            return setTempo(props.tempo);
        }
    }, [props]);

    useEffect(() => {
        if (played) props.played(played)
    }, [played])

    const formattedTimestamp = useMemo(() => {
        if (!data || !data.horario) return '';

        const dataObj = new Date(data.horario);
        const miliAgora = Date.now();
        const agora = new Date(miliAgora);

        if (agora - dataObj < 86400000) {
            const horasAtras = Math.floor((agora - dataObj) / 3600000);
            const minutosAtras = Math.floor((agora - dataObj) / 60000);

            if (horasAtras >= 1) return `Há ${horasAtras} ${horasAtras === 1 ? 'hora' : 'horas'}`;
            else if (minutosAtras < 1) return 'Agora';
            else return `Há ${minutosAtras} ${minutosAtras === 1 ? 'minuto' : 'minutos'}`;
        }

        const dia = dataObj.getDate().toString().padStart(2, '0');
        const mes = (dataObj.getMonth() + 1).toString().padStart(2, '0');
        const ano = dataObj.getFullYear();
        return `${dia}/${mes}/${ano}`;
    }, [data && data.horario]);

    useEffect(() => {
        if (data.avatar <= 15) setAvatarDraw(files[data.avatar]);
    }, [data.avatar]);

    const willHearts = useMemo(() => {
        if (!data || !data.legenda) return false;
        const heartCodes = [10084, 9829, 55357, 10083, 55358];

        for (let i = 0; i < data.legenda.length; i++) {
            for (let j = 0; j < heartCodes.length; j++) {
                if (data.legenda.charCodeAt(i) === heartCodes[j]) {
                    return true;
                }
            }
        }
        return false;
    }, [data && data.legenda]);

    return (
        <div ref={divPostRef}>
            <div>
                <div className='imgPost'>
                    <LazyPhoto src={data.media} alt={`Foto de ${data.name}`} />
                    <div className='linearFade'></div>
                    <div className='linearTopFade'></div>
                    {data.legenda && data.legenda.length > 0 && (
                        <div className='legenda'>
                            <p>{data.legenda}</p>
                        </div>
                    )}
                </div>
                <div className='infoPost'>
                    <div className='displayUser'>
                        {
                            data.avatar
                                ? (
                                    <div className='avatar'><img src={data.avatar !== '' ? (data.avatar <= 15 ? avatarDraw : data.avatar) : avatarThumb} alt={`Avatar de ${data.name}`} /></div>
                                )
                                : <></>
                        }
                        <div className='texts'>
                            <div className='name'><h2>{data.name}</h2></div>
                            <div className='postTime'><p>{formattedTimestamp}</p></div>
                        </div>
                    </div>
                    {showQr && evento && evento.length > 0 && <TagQR endpoint={endpoint} evento={evento} />}
                </div>
                <Assinatura aplicativo='stories' />
                {/* {willHearts &&
                    (
                        <img
                            src={GifHearts}
                            alt="Hearts"
                            style={{ position: 'absolute', bottom: '0', right: '0', width: '600px', opacity: '.14' }}
                        />
                    )} */}
            </div>
        </div>
    );
}

export default Slideshow;