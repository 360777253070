import axios from '../baseService';

const API_URL = process.env.REACT_APP_API_URL;

export async function sendContent(media, name, avatar, legenda, endpoint) {
    const sendUrl = `${API_URL}/stories/envio/${endpoint}`;
    const response = await axios.post(sendUrl, { media, name, avatar, legenda, endpoint });
    return response.data;
}

export async function sendPubliContent(publi, token) {
    const sendUrl = `${API_URL}/stories/publi/envio`;
    const response = await axios.post(sendUrl, { publi, token });
    return response.data;
}

export async function pegaAPI(page, manage = false, endpoint) {
    let filtered = [];
    const apiUrl = `${API_URL}/data/stories/${endpoint}`;
    const response = await axios.post(apiUrl, { page, endpoint });
    if (page === 'stories' && !manage) {
        for (let i in response.data) {
            if (response.data[i].pendente === 'n') filtered.push(response.data[i]);
        }
        return filtered;
    }
    else if (page === 'publi') {
        if (manage) {
            for (let i in response.data) {
                if (response.data[i].status === 'publicado' || response.data[i].status === 'ativo') filtered.push(response.data[i]);
            }
            return filtered;
        }
        else {
            for (let i in response.data) {
                if (response.data[i].status === 'ativo') filtered.push(response.data[i]);
            }
            return filtered;
        }
    }
    else {
        return response.data;
    }
}